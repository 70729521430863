<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <v-layout
      wrap
    >
      <v-flex
        md12
      >
        <material-card
          :title="$t('footer.listTitle')"
          color="green"
          flat
          full-width
        >
          <template>
            <v-btn
              color="success"
              dark
              style="float: right;"
              @click="onShowModalAddFooter(null)"
            >
              {{ $t('common.add') }}
            </v-btn>
          </template>
          <v-data-table
            :no-data-text="$t('common.noDataAvailable')"
            :headers="footerHeaders"
            :items="footerList"
            :single-select="true"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="text--darken-3 font-medium"
                v-text="$t(header.text)"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <td>{{ item.stt }}</td>
              <td>{{ item.title }}</td>
              <td style="max-width: 250px; white-space: pre-wrap;">{{ item.content }}</td>
              <td class="text-xs-center">
                <v-menu
                  v-model="item.selectedButton"
                  offset-y
                  transition="slide-y-transition"
                  bottom
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      color="indigo"
                      dark
                      v-on="on"
                    >
                      <v-icon>mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <!-- Update supplier -->
                    <v-tooltip
                      top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          class="mx-2"
                          fab
                          small
                          color="primary"
                          style="color: #ffffff;"
                          v-on="on"
                          @click="onShowModalAddFooter(item)">
                          <v-icon dark>mdi-square-edit-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('common.update') }}</span>
                    </v-tooltip>
                    <!-- Delete supplier -->
                    <v-tooltip
                      top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          class="mx-2"
                          fab
                          small
                          color="red"
                          style="color: #ffffff;"
                          v-on="on"
                          @click="onShowModalDeleteFooter(item.id)">
                          <v-icon dark>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('common.delete') }}</span>
                    </v-tooltip>
                  </v-card>
                </v-menu>
              </td>
            </template>
          </v-data-table>
        </material-card>
      </v-flex>
    </v-layout>
    <pagination
      :total="footerPaginate.totalPage"
      :current-page="footerPaginate.currentPage"
      :row-per-page="footerPaginate.rowPerPage"
      @onPageChange="onPageChange"
    />
    <addFooterModal
      ref="addFooterModal"
      @createFooterSuccess="onCreateFooterSuccess"/>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"/>
    <confirm-modal
      ref="confirmModal"
      :title="confirmModalTitle"
      @onConfirm="onDeleteFooter()"
    />
    <!-- <userDetailModal ref="userDetailModal" /> -->
    <loadingBar :is-loading="isLoading" />
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import ConfirmModal from 'Components/ConfirmModal'
import AddFooterModal from './Add.vue'
import ToastType from 'enum/toastType'
export default {
  components: {
    ConfirmModal,
    AddFooterModal
  },
  data: () => ({
    footerHeaders: [
      {
        sortable: false,
        text: 'common.nonumber',
        value: 'stt'
      },
      {
        sortable: false,
        text: 'footer.title',
        value: 'title'
      },
      {
        sortable: false,
        text: 'footer.content',
        value: 'content'
      },
      {
        sortable: false,
        text: 'common.actions',
        value: 'actions',
        align: 'center'
      }
    ],
    footerList: [],
    footerPaginate: {
      totalPage: 1,
      currentPage: 1,
      rowPerPage: 1
    },
    confirmModalTitle: '',
    isLoading: false,
    footerId: null
  }),
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR',
      'FOOTERS_CONFIG_LIST_DATA'
    ])
  },
  watch: {
    FOOTERS_CONFIG_LIST_DATA () {
      let res = this.FOOTERS_CONFIG_LIST_DATA
      // Handle paginate
      this.footerPaginate.currentPage = res.paginate.currentPage
      this.footerPaginate.totalPage = res.paginate.totalPage
      this.footerPaginate.rowPerPage = res.paginate.perPage / 2
      // Handle data
      let data = res.results
      this.footerList = []
      for (let i = 0, size = data.length; i < size; i++) {
        let advertisementInfoObj = {
          stt: i + 1 + (res.paginate.currentPage - 1) * res.paginate.pageSize,
          id: data[i].id,
          title: data[i].title,
          content: data[i].content,
          selectedButton: false
        }
        this.footerList.push(advertisementInfoObj)
      }
      this.isLoading = false
    }
  },
  created () {
    this.getFooterList()
  },
  methods: {
    /**
     * Add advertisement success
     */
    onCreateFooterSuccess: function () {
      this.getFooterList()
    },
    /**
     * Delete advertisement
     */
    onDeleteFooter: function () {
      this.DELETE_FOOTER_CONFIG({ id: this.footerId }).then(
        function () {
          this.ON_SHOW_TOAST({
            message: this.$t('common.success'),
            styleType: ToastType.SUCCESS
          })
          this.getFooterList()
        }.bind(this)
      ).catch(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('login.somethingWentWrong'),
            'styleType': ToastType.ERROR
          })
        }
      )
    },
    /**
     * Search
     */
    onSearch: function () {
      this.getFooterList()
    },
    /**
     * Show modal add user
     */
    onShowModalAddFooter: function (item) {
      this.$refs.addFooterModal.onShowModal(item)
    },
    /**
     * Show modal delete user
     */
    onShowModalDeleteFooter: function (id) {
      this.footerId = id
      this.confirmModalTitle = this.$t('footer.actions.confirmDeleteTitle')
      this.$refs.confirmModal.onShowModal(id)
    },
    /**
     * Get advertisement list
     */
    getFooterList: function () {
      let filter = {
        params: {
          page: this.footerPaginate.currentPage
        }
      }
      this.isLoading = true
      this.GET_FOOTERS_CONFIG_LIST(filter)
    },
    /**
     * Page change
     */
    onPageChange: function (page) {
      this.footerPaginate.currentPage = page
      this.getFooterList()
    },
    ...mapActions([
      'GET_FOOTERS_CONFIG_LIST',
      'ON_SHOW_TOAST',
      'DELETE_FOOTER_CONFIG'
    ])
  }
}
</script>
<style lang="scss" scoped>
</style>
