<template>
  <v-dialog
    v-model="isShowModal"
    persistent
    max-width="800px">
    <v-card>
      <material-card
        :title="id ? $t('footer.actions.update') : $t('footer.actions.add')"
        tile
        color="green"
        full-width
      >
        <v-container grid-list-xl>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-layout wrap>
              <!-- Title -->
              <v-flex
                xs12
                md12>
                <v-text-field
                  v-model="title"
                  :label="$t('bannerInfo.title')"
                  :name="$t('bannerInfo.title')"
                  type="text"
                  class="required"
                />
              </v-flex>
              <!-- Description -->
              <v-flex
                xs12
                md12>
                <v-textarea
                  v-model="content"
                  :label="$t('footer.content')"
                  :name="$t('footer.content')"
                  type="text"
                />
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          color="error darken-1"
          @click="onCloseModal"
        >{{ $t("common.close") }}
        </v-btn>
        <v-btn
          small
          color="success darken-1"
          @click="onConfirm"
        >{{ $t("common.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  data: () => ({
    valid: false,
    isShowModal: false,
    id: null,
    title: null,
    content: null
  }),
  methods: {
    onConfirm: function () {
      let filter = {
        title: this.title,
        content: this.content.trim()
      }
      if (this.id) {
        this.UPDATE_FOOTER_CONFIG({ id: this.id, ...filter }).then(
          function () {
            this.$emit('createFooterSuccess')
            this.isShowModal = false
          }.bind(this)
        )
      } else {
        this.CREATE_FOOTER_CONFIG(filter).then(
          function () {
            this.$emit('createFooterSuccess')
            this.isShowModal = false
          }.bind(this)
        )
      }
    },
    onCloseModal: function () {
      this.isShowModal = false
    },
    onShowModal: function (item) {
      this.isShowModal = true
      if (item) {
        this.id = item.id
        this.title = item.title
        this.content = item.content
      } else {
        this.id = null
        this.title = null
        this.content = null
      }
    },
    ...mapActions([
      'CREATE_FOOTER_CONFIG',
      'UPDATE_FOOTER_CONFIG'
    ])
  }
}
</script>

<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
</style>
